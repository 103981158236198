export default [
  {
    route: { name: 'home' },
    title: 'Home',
  },
  {
    route: { name: 'about' },
    title: 'Gang van zaken',
  },
  {
    route: { name: 'membership' },
    title: 'Lidmaatschap',
  },
  {
    route: { name: 'lesson' },
    title: 'Proefles',
  },
  {
    route: { name: 'competition' },
    title: 'Competitie',
    children: [
      {
        route: { name: 'competition.information' },
        title: 'Informatie',
      },
      {
        title: 'Komende wedstrijden',
        href: 'https://www.toernooi.nl/sport/clubteams.aspx?id=352DB35F-66C1-48CC-906D-205F68F73C42&cid=18',
      },
      {
        title: 'BBF Badminton',
        href: 'https://bbfbadminton.nl/vereniging-abc',
      },
      {
        title: 'hr',
      },
      {
        title: 'Uitslagen',
        href: 'https://www.toernooi.nl/sport/clubstandings.aspx?id=352DB35F-66C1-48CC-906D-205F68F73C42&cid=18',
      },
    ],
  },
  {
    route: { name: 'sponsor' },
    title: 'Sponsor',
  },
  {
    route: { name: 'calendar' },
    title: 'Kalender',
  },
  {
    route: { name: 'contact' },
    title: 'Contact',
  },
];
